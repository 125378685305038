import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { Divider } from '@/components/common/Divider';
import { Paragraph } from '@/components/common/MarkUp';
import { Surface } from '@/components/common/Surface';
import { Col, Row } from '@/components/layout/Grid';
import { Section } from '@/components/layout/Section';
import { Stack } from '@/components/layout/Stack';

import type { Sizes } from '@/components/layout/Grid';
import type { SurfaceColourTokenType } from '@/types/colours';
import type { SanityStatsType } from '@/types/sanity';

export interface StatsProps extends Omit<SanityStatsType, '_type'> {
  sizes?: Sizes;
}

export const StatsComponent = ({ statistics, sizes }: StatsProps) => {
  return (
    <Row>
      <Col
        sizes={
          sizes || {
            base: 4,
            sm: statistics.length < 3 ? 8 : 12,
            lg: statistics.length < 3 ? 8 : 10,
          }
        }
        start={{
          sm: statistics.length < 3 ? 3 : 1,
          lg: statistics.length < 3 ? 1 : 2,
        }}
      >
        <StatContainer
          borderRadius="--radius-component-xl"
          padding="--space-component-xxl"
          statColor={'--surface-inverse-base'}
        >
          {statistics.map((stat, index) => {
            return (
              <>
                <Stack spacing="--space-component-lg">
                  <StyledParagraph size="heading-lg">
                    {stat.figure}
                  </StyledParagraph>
                  <BoldParagraph size="subheading-sm">
                    {stat.label}
                  </BoldParagraph>
                  {stat?.description && (
                    <StyledParagraph size="subheading-sm">
                      {stat.description}
                    </StyledParagraph>
                  )}
                </Stack>
                {index < statistics.length - 1 && (
                  <DividerWrapper>
                    <Divider
                      orientation={{ base: 'horizontal', sm: 'vertical' }}
                    />
                  </DividerWrapper>
                )}
              </>
            );
          })}
        </StatContainer>
      </Col>
    </Row>
  );
};

export const Stats = ({
  statistics,
  sizes,
  backgroundColour,
  blockSpacing,
  paddingAdjustment,
}: StatsProps) => {
  return (
    <Section
      verticalPadding={paddingAdjustment ?? true}
      spacing={blockSpacing}
      backgroundColour={backgroundColour?.token}
      className="stats"
    >
      <StatsComponent statistics={statistics} sizes={sizes}></StatsComponent>
    </Section>
  );
};

const StatContainer = styled(Surface)<{ statColor: SurfaceColourTokenType }>`
  background-color: ${({ statColor }) => 'var(' + statColor + ')'};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;

  ${screen.sm} {
    flex-direction: row;
    text-align: left;
    margin: 0;
  }
`;

const DividerWrapper = styled.div`
  padding: var(--space-component-xxl) 0px;
  ${screen.sm} {
    padding: 0px var(--space-component-xxl);
  }
  hr {
    background-color: var(--border-inverse-base);
  }
`;

const BoldParagraph = styled(Paragraph)`
  font-weight: normal;
  color: var(--text-eclipse);

  &:not(:last-child) {
    font-weight: bold;
  }
`;

const StyledParagraph = styled(Paragraph)`
  color: var(--text-eclipse);
`;
